import { render, staticRenderFns } from "./editCourtsPrice.vue?vue&type=template&id=2b94f37a&scoped=true&"
import script from "./editCourtsPrice.vue?vue&type=script&lang=js&"
export * from "./editCourtsPrice.vue?vue&type=script&lang=js&"
import style0 from "./editCourtsPrice.vue?vue&type=style&index=0&id=2b94f37a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b94f37a",
  null
  
)

export default component.exports