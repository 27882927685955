<template>
  <div class="edit-term-prices">

    <draggable-dynamic-table ref="editCourtsPriceTable"
                             :in-modal="true"
                             :active-grid="true"
                             :columns="columnsLabel"
                             :options="options"
                             @column:select="consoleLog($event)"
                             v-model="data"/>


    <button id="editCourtPriceBTN" v-show="false" @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
  import CustomIcon from '../../../../../../components/customIcon/customIcon.vue'
  import {getTimePrices, editTimePrices} from '@/http/requests/club/timePrices'
  import {addComma} from '../../../../../../assets/js/functions'

  export default {
    name: 'EditCourtPrice',
    metaInfo() {
      return {
        title: this.$t('courts.price.edit.title')
      }
    },
    components: {
      CustomIcon,
      DraggableDynamicTable
    },
    props: {
      courtId: 0
    },
    data() {
      return {
        loadingTimer: 0,
        options: {
          id: 'editCourtsPriceTable',
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'day_7',
            i18n: 'courts.price.table.header.friday',
            width: 'calc((100% / 10))',
            minWidth: 120,
            editable: true,
            returnValue: false,
            valueType: 'price',
          },
          {
            field: 'day_6',
            i18n: 'courts.price.table.header.thursday',
            width: 'calc((100% / 10))',
            minWidth: 120,
            editable: true,
            returnValue: false,
            valueType: 'price',
          },
          {
            field: 'day_5',
            i18n: 'courts.price.table.header.wednesday',
            width: 'calc((100% / 10))',
            minWidth: 120,
            editable: true,
            returnValue: false,
            valueType: 'price',
          },
          {
            field: 'day_4',
            i18n: 'courts.price.table.header.tuesday',
            width: 'calc((100% / 10))',
            minWidth: 120,
            editable: true,
            returnValue: false,
            valueType: 'price',
          },
          {
            field: 'day_3',
            i18n: 'courts.price.table.header.monday',
            width: 'calc((100% / 10))',
            minWidth: 120,
            editable: true,
            returnValue: false,
            valueType: 'price',
          },
          {
            field: 'day_2',
            i18n: 'courts.price.table.header.sunday',
            width: 'calc((100% / 10))',
            minWidth: 120,
            editable: true,
            returnValue: false,
            valueType: 'price',
          },
          {
            field: 'day_1',
            i18n: 'courts.price.table.header.saturday',
            width: 'calc((100% / 10))',
            minWidth: 120,
            editable: true,
            returnValue: false,
            valueType: 'price',
          },
          {
            field: 'termName',
            i18n: 'courts.price.table.header.termName',
            width: '150px',
            minWidth: 150,
            align: 'center',
            // locked: true,
            // sortable: true,
            // footer: {
            /*type: 'auto-counter'*/
            // }
          }
        ],
        data: [],
        filters: {court: `${this.courtId || this.$route.params.id}`},
        sorts: ['order[0]=term_id,asc'],
        selectedUser: {},
        page: 1,
        endedList: false
      }
    },
    created() {
      this.getTerms()
    },
    methods: {
      getTerms() {
        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (!this.endedList) {
            if (this.$refs.editCourtsPriceTable && this.data.length === 0) {
              this.$refs.editCourtsPriceTable.loadMoreStatus = 'FirstLoad'
            } else if (this.$refs.editCourtsPriceTable && this.data.length > 0) {
              this.$refs.editCourtsPriceTable.loadMoreStatus = 'Loading'
            }

            getTimePrices(this.page, this.filters, this.sorts).then((res) => {
              this.endedList = res.data.data.length === 0
              let data = {}
              res.data.data.forEach((court) => {
                if (court.term) {
                  if (!data[`term_${court.term.id}`]) {
                    data[`term_${court.term.id}`] = {}
                  }
                  data[`term_${court.term.id}`]['termName'] = court.term.name || ''
                  data[`term_${court.term.id}`]['termId'] = court.term.id
                  data[`term_${court.term.id}`]['flag'] = 'NEW'
                  data[`term_${court.term.id}`][`day_${court.week_day}`] = addComma(court.price)
                }
              })
              const dataArray = Object.values(data)
              setTimeout(() => {
                dataArray.forEach((item) => {
                  this.data.push(item)
                })
              }, 100)

              if (this.$refs.editCourtsPriceTable) this.$refs.editCourtsPriceTable.loadMoreStatus = ''
            })
                .catch((error) => {
                  if (this.$refs.editCourtsPriceTable && !axios.isCancel(error)) this.$refs.editCourtsPriceTable.loadMoreStatus = 'Danger'
                })
          }
        }, 400)
      },

      sendData() {
        let payload = []

        this.data.forEach((court) => {
          if (court.flag === 'UPDATED') {
            for (let i = 1; i <= 7; i++) {
              payload.push({
                week_day: i,
                court_id: this.courtId || this.$route.params.id,
                term_id: court.termId,
                price: court[`day_${i}`] ? court[`day_${i}`].replaceAll(',', '') : '0'
              })
            }
          }
        })

        if (payload.length > 0) {
          editTimePrices({prices: payload}).then((response) => {
            this.$vs.notify({
              title: this.$t('alert.message.title'),
              text: this.$t('courts.price.notifications.edit.success'),
              icon: 'icon-check',
              iconPack: 'feather',
              time: 2400,
              color: 'success'
            })
            this.$emit('insert')

          }).catch((error) => {
            if (axios.isCancel(error)) {
              this.$vs.notify({
                title: this.$t('alert.duplicateRequest.title'),
                text: this.$t('alert.duplicateRequest.message'),
                icon: 'icon-alert-circle',
                iconPack: 'feather',
                time: 2400,
                color: 'warning'
              })
            } else {
              const error_mapper = {
                'name': '',
              }

              switch (error.response.status) {
                case 422:
                  Object.keys(error.response.data.errors).forEach((error_key) => {
                    const err = error_key.toString().split('.')
                    if (error_mapper[err[err.length - 1]]) {
                      this.$vs.notify({
                        title: this.$t('alert.error.title'),
                        text: error_mapper[err[err.length - 1]],
                        color: 'danger',
                        icon: 'icon-alert-circle',
                        iconPack: 'feather',
                        time: 2400
                      })
                      error_mapper[err[err.length - 1]] = null
                    }
                  })
                  break

                default:
                  this.$vs.notify({
                    title: this.$t('alert.error.title'),
                    text: this.$t('courts.price.notifications.edit.error'),
                    color: 'danger',
                    icon: 'icon-alert-circle',
                    iconPack: 'feather',
                    time: 2400
                  })
                  break
              }
            }
          })
        }
      },

      consoleLog() {
        // console.log(event)
      },
      handleClick(id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .edit-term-prices {
    height: 100%;
  }
</style>
